<script lang="ts" setup>

import { ref, computed, onMounted, onUnmounted, withDefaults } from 'vue'
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'

import type { Props } from '@/components/ImageShowcase/types'
import './_index.scss'

const props = withDefaults(defineProps<Props>(), {
  images: () => [],
  intervalDurationDesktop: 1500,
  intervalDurationMobile: 3000
})
const activeIndex = ref<number>(0)
const interval = ref<NodeJS.Timeout | null>(null)
const initialTimer = ref<NodeJS.Timeout | null>(null)

const breakpoints = useBreakpoints(breakpointsTailwind)
const isDesktop = ref(false)

const intervalDuration = computed(() => {
  return isDesktop.value ? props.intervalDurationDesktop : props.intervalDurationMobile
})

const crossfadeToNextPicture = () => {
  activeIndex.value = (activeIndex.value + 1) % props.images.length
}

const initializeCrossfadeInterval = () => {
  initialTimer.value = setTimeout(crossfadeToNextPicture, 300)

  interval.value = setInterval(crossfadeToNextPicture, intervalDuration.value)
}

const clearCrossfadeInterval = () => {
  if (!interval.value) return

  clearInterval(interval.value)
  interval.value = null

  if (!initialTimer.value) return

  clearTimeout(initialTimer.value)
  initialTimer.value = null
}

const pictureSlideShow = ref()

const setFixedCtaObserver = () => {
  const imagesHolderObserver = new IntersectionObserver(
    entries => entries.forEach(entry => entry.isIntersecting ? initializeCrossfadeInterval() : clearCrossfadeInterval()),
    { threshold: 0.5 })

  imagesHolderObserver.observe(pictureSlideShow.value)
}

onMounted(() => {
  isDesktop.value = breakpoints.greaterOrEqual('lg').value
  if (!isDesktop.value) setFixedCtaObserver()
})

onUnmounted(clearCrossfadeInterval)

</script>

<script lang="ts">
export default {
  name: 'ImageShowcase'
}
</script>

<template>
  <div
    ref="pictureSlideShow"
    :data-lh-id="dataLhId"
    class="image-show-case__container"
    @mouseover="initializeCrossfadeInterval"
    @mouseleave="clearCrossfadeInterval">
    <img
      v-for="(imgObj, index) in images"
      :key="imgObj.src"
      :src="imgObj.src"
      :alt="imgObj.alt"
      :loading="lazyLoad ? 'lazy' : 'eager'"
      class="image-show-case__image"
      :class="{ 'image-show-case__image--active': activeIndex === index }"
      :style="{ 'transition': `all ${intervalDuration / 1000}s cubic-bezier(0.45, 0, 0.55, 1)` }">
  </div>
</template>
